import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-sioux-falls-south-dakota.png'
import image0 from "../../images/cities/scale-model-of-statue-of-david-in-sioux-falls-south-dakota.png"
import image1 from "../../images/cities/scale-model-of-escapades-escape-room-sioux-falls-sd-in-sioux-falls-south-dakota.png"
import image2 from "../../images/cities/scale-model-of-great-plains-zoo-&-delbridge-museum-of-natural-history-in-sioux-falls-south-dakota.png"
import image3 from "../../images/cities/scale-model-of-pettigrew-home-&-museum-in-sioux-falls-south-dakota.png"
import image4 from "../../images/cities/scale-model-of-downtown-sioux-falls-in-sioux-falls-south-dakota.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Sioux Falls'
            state='South Dakota'
            image={image}
            lat='43.5383'
            lon='-96.732'
            attractions={ [{"name": "Statue of David", "vicinity": "200 S 2nd Ave, Sioux Falls", "types": ["point_of_interest", "establishment"], "lat": 43.54524079999999, "lng": -96.72321119999998}, {"name": "Escapades Escape Room Sioux Falls SD", "vicinity": "3313 S Western Ave, Sioux Falls", "types": ["store", "point_of_interest", "establishment"], "lat": 43.515277, "lng": -96.75153419999998}, {"name": "Great Plains Zoo & Delbridge Museum of Natural History", "vicinity": "805 S Kiwanis Ave, Sioux Falls", "types": ["zoo", "amusement_park", "point_of_interest", "establishment"], "lat": 43.5392292, "lng": -96.76269889999998}, {"name": "Pettigrew Home & Museum", "vicinity": "131 N Duluth Ave, Sioux Falls", "types": ["museum", "point_of_interest", "establishment"], "lat": 43.5483882, "lng": -96.73463679999998}, {"name": "Downtown Sioux Falls", "vicinity": "230 S Phillips Ave #306, Sioux Falls", "types": ["point_of_interest", "establishment"], "lat": 43.5449278, "lng": -96.72636139999997}] }
            attractionImages={ {"Statue of David":image0,"Escapades Escape Room Sioux Falls SD":image1,"Great Plains Zoo & Delbridge Museum of Natural History":image2,"Pettigrew Home & Museum":image3,"Downtown Sioux Falls":image4,} }
       />);
  }
}